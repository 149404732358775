<template>
  <div class="column has-text-right correction-section">
    <a
      target="_blank"
      :href="`mailto:corrections@datajoe.com?subject=${
        clientName} Updates | ${directoryName} | ${recordName}`"
      class="button is-primary"
    >Submit Changes</a>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    recordName: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      clientName: (state) => state.name,
      directoryName: (state) => state.route.params.directory,
    }),
  },
};
</script>
