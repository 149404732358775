<template>
  <div class="standard-data">
    <div v-if="hasLocations" class="address">
      <p class="has-text-weight-bold">
        LOCATION
      </p>
      <p>{{ location.name }}</p>
      <p>{{ location.address }}</p>
      <p>
        {{ location.city }},
        {{ location.state }}
        {{ location.zip }}
      </p>
    </div>

    <p v-if="hasLocations && location.phone" class="is-together result-phone">
      <span class="icon"><fa icon="phone" /></span>
      <a :href="`tel:${location.phone}`">
        {{ location.phone }}
      </a>
    </p>

    <div v-for="field in processedContactFields" :key="field.name" class="is-field">
      <h2 class="has-text-weight-bold">
        {{ field.name.toUpperCase() }}
      </h2>
      <div v-for="value in field.values" :key="value.year">
        <span v-if="value.year" class="has-text-weight-bold">{{ value.year }}:</span>
        <span v-else>
          <a v-if="field.type === 'email'" :href="`mailto:${value.value}`">
            {{ value.value }}
          </a>
          <a v-else-if="field.type === 'url'" :href="value.value">
            {{ value.value }}
          </a>
          <span v-else>{{ value.value }}</span>
        </span>
      </div>
    </div>

    <div v-if="record['web-profile']" class="is-together">
      <fa icon="globe" />
      <a :href="format('url', record['web-profile'])" target="_blank">
        {{ record['web-profile'] }}
      </a>
    </div>
  </div>
</template>

<script>
import formatField from '@/mixins/fields/formatField';
import { mapGetters } from 'vuex';

export default {
  mixins: [formatField],
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      fields: 'fields/get',
    }),
    hasLocations() {
      return this.record?.locations?.[0]?.address;
    },
    location() {
      return this.record.locations[0];
    },
    contactFields() {
      return this.fields.filter((field) => field.category === 'Contact' && field.name !== 'Phone');
    },
    processedContactFields() {
      return this.contactFields
        .filter((field) => this.record[field.key] !== undefined)
        .map((field) => ({
          name: field.name,
          values: this.mapFields(field),
        }));
    },
  },
  methods: {
    mapFields(field) {
      return [
        {
          value: this.formatField(field.type, this.record[field.key]),
        },
      ];

      // return Object.keys(this.record[field.key])
      //   .sort((firstYear, secondYear) => (secondYear - firstYear))
      //   .map((fieldYear) => ({
      //     year: fieldYear,
      //     value: formatField(field.type, this.record[field.key][fieldYear]),
      //   }));
    },
    format(type, field) {
      return this.formatField(type, field);
    },
  },
};
</script>
