<template>
  <div :id="uniqueId" class="column is-record-ad is-hidden-mobile has-text-centered" />
</template>

<script>
import postscribe from 'postscribe';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      uniqueId: `${window.performance.now()}-${Math.random().toString(36).substring(7)}`,
    };
  },
  computed: {
    ...mapState('advertisements', {
      record_ads: (state) => state.record_ads,
    }),
  },
  mounted() {
    setTimeout(() => {
      if ((this.record_ads?.length ?? 0) > 0) {
        postscribe(`#${this.uniqueId}`, this.record_ads[Math.floor(Math.random() * this.record_ads.length)]);
      }
    }, 1000);
  },
};
</script>
