<template>
  <a class="button floated-buttons is-dark" @click.prevent="back">
    Back
  </a>
</template>

<script>
export default {
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
