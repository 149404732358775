<template>
  <div id="recordView" class="page">
    <SearchHero />

    <ActiveRefinements class="is-hidden" />

    <RecordMap :record="record" />

    <section id="profile" class="columns is-centered">
      <div class="column is-10 profile-shadow">
        <div class="columns is-centered is-multiline">
          <BackButton class="column is-narrow is-hidden-mobile" />
          <div class="column columns is-multiline">
            <div
              id="image-profile-section"
              class="column profile-section"
              :style="record['image-uri'] ? '' : 'order: 2'"
            >
              <div v-if="record['image-uri']" id="profile-image" class="has-text-centered">
                <img :src="record['image-uri']" :alt="record.name">
                <SponsoredBadge v-if="record.badge" />
                <ExpertBadge :expert="record.expert" />
              </div>

              <h1 class="title is-hidden-desktop is-hidden-tablet-only" v-html="record.name" />

              <PrimaryFilterText :record="record" />

              <AddressBlock :record="record" />
            </div>

            <div
              id="name-profile-section"
              class="column profile-section"
              :style="record['image-uri'] ? '' : 'order: 1'"
            >
              <ExpertBadge v-if="!record['image-uri']" :expert="record.expert" />
              <h1 class="title is-hidden-mobile" v-html="record.name" />

              <RecordFacets :record="record" />

              <div v-for="field in filteredFields" :key="field.key" class="is-field">
                <template v-if="record[field.key]">
                  <h2 class="has-text-weight-bold" v-html="field.name.toUpperCase()" />

                  <template v-if="isAnnualData(record[field.key])">
                    <p v-for="year in annualFields(record[field.key])" :key="year">
                      <span class="has-text-weight-bold">{{ year }}:</span>
                      <span v-html="format(field.type, record[field.key][year])" />
                    </p>
                  </template>

                  <p v-else class="subtitle is-size-6">
                    <a
                      v-if="field.type === 'email'"
                      :href="`mailto:${format(field.type, record[field.key])}`"
                    >
                      <span v-html="format(field.type, record[field.key])" />
                    </a>
                    <a
                      v-else-if="field.type === 'url'"
                      :href="format(field.type, record[field.key])"
                      v-html="record[field.key]"
                    />
                    <span v-else v-html="format(field.type, record[field.key])" />
                  </p>
                </template>
              </div>

              <DirectoryLinks :record="record" />
            </div>
          </div>

          <div
            class="column is-narrow has-text-centered profile-section"
            :style="record['image-uri'] ? '' : 'order: 3'"
          >
            <div id="profile-sharing" class="floated-buttons buttons is-right is-hidden-touch">
              <ExpertButton v-if="!record.expert" />
              <Sharing />
            </div>
            <RecordAd class="is-hidden-touch" />
          </div>
        </div>
        <Corrections :record_name="record.name" />
      </div>
    </section>
  </div>
</template>

<script>
import SearchHero from '@/components/Global/SearchHero.vue';
import Sharing from '@/components/Global/Sharing.vue';
import RecordAd from '@/components/Ads/RecordAd.vue';
import algoliasearch from 'algoliasearch/lite';
import Corrections from '@/components/Record/Corrections.vue';
import ExpertButton from '@/components/Record/ExpertButton.vue';
import SponsoredBadge from '@/components/Record/SponsoredBadge.vue';
import ExpertBadge from '@/components/Badges/Expert.vue';
import AddressBlock from '@/components/Record/AddressBlock.vue';
import DirectoryLinks from '@/components/Record/DirectoryLinks.vue';
import PrimaryFilterText from '@/components/Record/PrimaryFilterText.vue';
import { mapGetters, mapState } from 'vuex';
import formatField from '@/mixins/fields/formatField';
import BackButton from '@/components/Record/BackButton.vue';
import RecordMap from '@/components/Record/RecordMap.vue';
import RecordFacets from '@/components/Record/RecordFacets.vue';
import ActiveRefinements from '@/components/InstantSearch/ActiveRefinements.vue';

export default {
  mixins: [formatField],
  data() {
    return {
      record: {
        awards: {
          lvl0: [],
        },
        location: {
          phone: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          position: {},
        },
      },
      searchClient: algoliasearch(
        this.$store.state.algolia_app_id,
        this.$store.state.algolia_search_api_key,
      ),
    };
  },
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
      fields: 'fields/get',
      index: 'directory/index',
    }),
    ...mapState({
      metaDescription: (state) => state.meta_description,
      instanceName: (state) => state.name,
    }),
    filteredFields() {
      const filteredCategories = ['Location', 'Contact'];
      const filteredNames = ['Name', 'Phone', 'Latitude/longitude'];

      return this.fields
        .filter((field) => !filteredCategories.includes(field.category))
        .filter((field) => !filteredNames.includes(field.name));
    },
  },
  methods: {
    format(type, field) {
      return this.formatField(type, field);
    },
    isAnnualData(field) {
      return Object.keys(field)[0]?.length === 4;
    },
    initAlgolia() {
      // Set up the index, which we later use to search for the record
      const index = this.searchClient.initIndex(this.index);

      // Search for the record via Algolia
      // If it exists, use the first result for the view, otherwise return a 404
      // Remove `and-` because our slugify and Algolia handle ampersands weird
      index.search(this.$route.params.record.replace('-and-', '%'))
        .then(({ hits }) => {
          if (!hits || hits.length === 0) {
            this.$router.push('/404');
          }

          [this.record] = hits;
        });
    },
    annualFields(fields) {
      return Object.keys(fields).sort((a, b) => (b - a));
    },
  },
  mounted() {
    this.$store.dispatch('advertisements/get');
    this.initAlgolia();
  },
  components: {
    SearchHero,
    Sharing,
    RecordAd,
    Corrections,
    ExpertButton,
    SponsoredBadge,
    ExpertBadge,
    AddressBlock,
    DirectoryLinks,
    PrimaryFilterText,
    BackButton,
    RecordMap,
    RecordFacets,
    ActiveRefinements,
  },
  metaInfo() {
    const recordName = this.record.name;
    const { instanceName } = this;
    const recordBio = this.record?.bio ?? this.metaDescription;

    const title = `${recordName} - ${instanceName} Directory`;
    const meta = [
      {
        name: 'description',
        content: `${recordBio}`,
      },
    ];
    return { title, meta };
  },
};
</script>
