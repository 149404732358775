<template>
  <section
    class="hero is-medium is-paddingless"
    :class="{ 'is-record-map-container': hasLocation }"
  >
    <div v-if="hasLocation" class="hero is-medium" :class="{ 'is-record-map': hasLocation }">
      <l-map ref="map" :zoom="16" :center="position" :options="{ zoomControl: false }">
        <l-tile-layer ref="tileLayer" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <l-marker :lat-lng="position" />
      </l-map>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      map: (state) => state.map,
    }),
    hasLocation() {
      return this.map.enabled && this.record?.locations?.length > 0;
    },
    position() {
      return {
        lat: parseFloat(this.record.locations[0].position.lat),
        lng: parseFloat(this.record.locations[0].position.lng),
      };
    },
  },
};
</script>
